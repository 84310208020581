import React from "react"
import Layout from "../components/layout"
import {Link} from 'gatsby'
import Head from '../components/head'
import FeatureImg from "../images/rome-forum-view.jpg"
import SiteMap from "./sitemaplist"



// markup


const IndexPage = () => {
  return (
    <React.Fragment>

  
   {/* <Layout FeatureImg= {FeatureImg} > */}
   <Layout>
     <Head title='Home'/>
     <h1>In Rebus: Latin language and popular culture</h1>
     <p>The majority of texts and materials on this site have something to do with the Latin language, including its perception and use in popular culture (Latin quotes, tattoos, mottos, engravings, inscriptions etc). You will also find some resources about other languages.</p>
 
     <img className={"featureImage"} src={FeatureImg} alt="Rome"/>


    <SiteMap>

    </SiteMap>
   </Layout>
   </React.Fragment>
  )
}

export default IndexPage
