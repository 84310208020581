import  React from "react"
import { Link } from 'gatsby'
import * as sitemapStyles from './sitemaplist.module.scss';

// markup
const SiteMap = () => {
  return (
    <div className={sitemapStyles.sitemap}>

    <div className={sitemapStyles.links}>
    <h2 className={sitemapStyles.h2}>Quotes and Phrases</h2>
    <ul className={sitemapStyles.ul}>
      <li>
        <Link to = "/LatinQuotes/LatQuotes_a">Latin Quotes and Expressions</Link>
        </li>
      <li>
        <Link to ="/latinmottogenerator/">Latin Motto Generator</Link>
      </li>

      <li>
        <Link to = "/LatinMaxims/LegalQuotes_a">Latin Legal Maxims</Link>
      </li>

      <li>
        <Link to = "/latinbibleverses">Latin Bible verses</Link>
      </li>

      <li>
        <Link to = "/latinlovequotes">Latin quotes and sayings about love</Link>
      </li>

      <li>
        <Link to = "/latinphrases">Latiin phrases, a searchable database</Link>
      </li>

      <li>
        <Link to = "/latinquotesaboutwar/">Latiin quotes about war</Link>
      </li>

      <li>
        <Link to = "/blog/latin-quotes-about-strength/">Latiin quotes about strength, endurance and victory</Link>
      </li>

     

    </ul>
     </div>
     <div className={sitemapStyles.links}>
    <h2 className={sitemapStyles.h2}>Wordlists and dictionaries</h2>
    <ul>
      <li>
        <Link to = "/churchlatin">Brief intro to Church Latin, with a wordlist</Link>
        </li>
      <li>
        <Link to ="/latinderivatives/">Latin derivatives in the English language</Link>
      </li>

      <li>
        <Link to = "/latindictionaries">Latin dictionaries: how to choose the right one</Link>
      </li>

      <li>
        <Link to = "/mostcommonlatinwords">300 most commonly used Latin words</Link>
      </li>

      <li>
        <Link to = "/medicallatinterms">Medical Latin terminology</Link>
      </li>
      
      <li>
        <Link to = "/musicterms">Music terms originating in Latin</Link>
      </li>

      <li>
        <Link to = "/latinphilosophyterms">Latin philosophy terms and sayings</Link>
      </li>

      <li>
        <Link to = "/mnemoniclatin">Mnemonic Latin devices</Link>
      </li>


      <li>
        <Link to = "/uplelist">The "Uple" list</Link>
      </li>

    </ul>
    </div>
    <div className={sitemapStyles.links}>
    <h2 className={sitemapStyles.h2}>History</h2>
    <ul>
      <li>
        <Link to = "/blog/chinon">The Chinon parchment</Link>
        </li>
      <li>
        <Link to ="/blog/cambridgebull">University of Cambridge, a papal bull</Link>
      </li>

    </ul>
    </div>

    <div className={sitemapStyles.links}>
    <h2 className={sitemapStyles.h2}>Arts and Literature</h2>
    <ul>
      <li>
        <Link to = "/latinlovepoems">Love poems in Latin</Link>
        </li>
      <li>
        <Link to ="/latinprayers">Latin prayers</Link>
      </li>
      <li>
        <Link to ="/portugueselovepoems">Portuguese love poems</Link>
      </li>

      <li>
        <Link to ="/Posies/ringpoems_a">'Posies' or ring poems</Link>
      </li>

      <li>
        <Link to ="/sadquotes">Latin quotes about death, sadness and suffering</Link>
      </li>

      <li>
        <Link to ="/sundialmottos">Sundial mottos (primarily Latin)</Link>
      </li>

      <li>
        <Link to ="/greekgodsgame/">Learn the most important Greek gods and their functions</Link>
      </li>

    </ul>
    </div>


    <div className={sitemapStyles.links}>
    <h2 className={sitemapStyles.h2}>Other Languages</h2>
    <ul>
    <li>
        <Link to = "/friendshipquotes">Latin quotes about friendship</Link>
      </li>
      
      <li>
        <Link to = "/frenchmottos">French mottos and heraldic phrases</Link>
      </li>

      <li>
        <Link to = "/germanmottos">German mottos and heraldic phrases</Link>
      </li>

      <li>
        <Link to = "/italianphrases">Italian phrases and significnt words (with translations)</Link>
      </li>
    </ul>
    </div>

    </div>
  )
}

export default SiteMap
